import React, { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { formError, formGetValue, formSetValue } from "./formUtils";

const InputForwardRef = (props, ref) => {
  const { formik, initialValue, column, isFormDirty, dirtying } = props;

  const [value, setValue] = useState("");
  const [inited, setInited] = useState(false);

  useEffect(() => {
    const initValue = initialValue;
    if (!inited && initValue !== undefined && initValue !== null) {
      setValue(initValue);
    }
    setInited(true);
  }, [initialValue]);

  useEffect(() => {
    if (inited && column.value !== undefined) {
      setValue(column.value);
    }
  }, [formik, column]);

  useEffect(() => {
    if (inited) {
      formSetValue(formik, column, value);
      dirtying();
      formError(formik, column);
    }
  }, [value]);

  return (
    <input
      type="hidden"
      name={column.field}
      onChange={(event) => {
        setValue(event.target.value ?? "");
        return formik.handleChange;
      }}
      value={value}
    />
  );
};

const HiddenInput = forwardRef(InputForwardRef);

HiddenInput.propTypes = {
  formik: PropTypes.object.isRequired,
  initialValue: PropTypes.any,
  column: PropTypes.object.isRequired,
  isFormDirty: PropTypes.bool,
  dirtying: PropTypes.func.isRequired,
};

export default HiddenInput;
