import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import ChecklistIcon from "@mui/icons-material/Checklist";
import PlaylistAddCheckCircleIcon from "@mui/icons-material/PlaylistAddCheckCircle";
import {Autocomplete, Button, MenuItem, TextField} from "@mui/material";
import { getMyPositions } from "../../../services/position";
import DialogApply from "../../position/components/DialogApply";

const ApplyForwardRef = (props, ref) => {
  const { selectAll, passedFiltered, site } = props;

  useImperativeHandle(ref, () => ({
    passFiltered(passed) {
      setFiltered(passed);
    },
  }));

  const [positions, setPositions] = useState([]);
  const [position, setPosition] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [filtered, setFiltered] = useState(passedFiltered);

  const refDialog = useRef(null);

  useEffect(() => {
    const type = site === "md" ? 20 : site === "hc" ? 10 : 0;
    getMyPositions({ type, status: 30 }, ['id DESC'], 500)
      .then((response) => {
        setPositions(response.data);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <>
      <Grid container spacing={2} alignItems={"center"} sx={{ ml: 4, mb: 2 }}>
        <Grid>
          <Button
            variant={"contained"}
            size={"small"}
            startIcon={<ChecklistIcon />}
            color={"primary"}
            onClick={() => {
              selectAll();
            }}
            sx={{ p: 1 }}
          >
            Összes kijelölése
          </Button>
        </Grid>
        {filtered?.length > 0 && (
          <Grid>
            {!!positions.length && <Autocomplete
                disablePortal
                value={position}
                onChange={(event, newValue) => {
                  // console.log('newValue', newValue);
                  setPosition(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  // console.log('newInputValue', newInputValue);
                  setInputValue(newInputValue);
                }}
                getOptionLabel={(option) => {
                  // console.log('getOptionLabel option', option)
                    return `${option.name} (${option.id})`;
                }}
                isOptionEqualToValue={(option, value) => {
                  return option.id === value.id;
                }}
                renderOption={(props, option) => {
                  // console.log('renderOption option', option);
                  return (
                      <li {...props} key={option.id}>
                        {`${option.name} (${option.id})`}
                      </li>
                  );
                }}
                id="combo-positions-to-apply"
                helperText="Pozícióhoz kiválasztása"
                options={positions}
                sx={{width: 300, marginBottom: 3}}
                renderInput={(params) => (
                    <TextField {...params} label="Pozíció"/>
                )}
            />}
            {!positions.length && (<p className={'alert alert-danger'} style={{ padding: "8px 12px", marginBottom: 0}}>Nincs hozzárendelt aktív pozíció</p>)}
          </Grid>
        )}
        {position?.id && (
          <Grid>
            <Button
              variant={"contained"}
              size={"small"}
              startIcon={<PlaylistAddCheckCircleIcon />}
              color={"success"}
              onClick={() => {
                if (refDialog.current) {
                  refDialog.current.open();
                }
              }}
              sx={{ p: 1 }}
            >
              Kijelöltek ({filtered.length})<br />
              pozícióhoz rendelése
            </Button>
          </Grid>
        )}
      </Grid>
      {position?.id && filtered.length && (
        <DialogApply
          position={positions.find((item) => item.id === position.id)}
          userIds={filtered}
          ref={refDialog}
        ></DialogApply>
      )}
    </>
  );
}

const Apply = forwardRef(ApplyForwardRef);

Apply.propTypes = {
  selectAll: PropTypes.func,
  // filtered: PropTypes.arrayOf(PropTypes.number),
  site: PropTypes.string.isRequired,
};

export default Apply;
